<template>
  <div class="today_condions">
    <p class="today_condions__title">Состояние на сегодня</p>
    <div class="today_condions__items">
      <div v-for="(value, key) in info" :key="key">
        <p v-text="types[key]"></p>
        <p v-text="value"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
  data() {
    return {
      types: {
        room_count: "Свободно: ",
        busy_rooms: "Занято: ",
        empty_rooms: "Пусто: ",
        sold_rooms: "Продано: ",
        bus_count: "Автобусов всего: ",
        empty_seat_place_count: "Свободно: ",
        seat_place_count: "Мест всего: ",
        sold_seat_place_count: "Продано: ",
      },
    };
  },
};
</script>

<style lang="scss">
.today_condions {
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 20px;
  display: inline-block;
  margin: 40px 0px;
  &__title {
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    margin-bottom: 20px;
  }
  &__items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 40px;
      &:last-of-type {
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        &:first-of-type {
          font-size: 16px;
          line-height: 19px;
          color: #c4c4c4;
        }
        &:last-of-type {
          font-size: 16px;
          line-height: 19px;
          color: #ff8413;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>

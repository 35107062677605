import axios from "axios";
const url = '/sales-dynamic/'

export const dynamicService = {
    // получить календарь
    async getCalendar(params) {
        return (await axios.get(`${url}calendar/`, {
            params
        })).data
    },
    // получить категории и номера в одном запросе для изменения брони
    async getCategories(params) {
        return (await axios.get(`${url}categories/`, {
            params
        })).data
    },
    // получить отели для фильтрации
    async getHotels(params) {
        return (await axios.get(`${url}companies/`, {
            params
        })).data
    },
    // получить состояние на сегодня
    async getStatistics(params) {
        return (await axios.get(`${url}statistics/`, {
            params
        })).data
    },
    // изменить бронь
    async changeBooking(body) {
        return (await axios.post(`${url}change-room/`, body)).data
    },



    // get bus 
    async getSalesDynamic(params, name) {
        return (await axios.get(`${url}${name}/`, {
            params
        })).data
    },
    async deleteSalesDynamic(id, name){
        return await axios.delete(`/${name}-routes/${id}/`)
    },
    // статус на сегодня bus
    async getToday(params, name) {
        return (await axios.get(`${url}${name}/today-status/`, {
            params
        })).data
    },
    // взять автобус определленый
    async getConcrete(params, name) {
        return (await axios.get(`${url}${name}/seat-places/`, {
            params
        })).data
    },
}
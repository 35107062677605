<template>
  <div class="grid_actions">
      <v-btn icon @click.stop="onEdit">
          <img v-if="!eyeEdit" src="@/assets/images/ui/edit.svg" alt="">
          <v-icon v-else color="primary">mdi-eye</v-icon>
      </v-btn>
      <v-btn icon @click.stop="onDelete">
        <img src="@/assets/images/ui/delete.svg" alt="">
      </v-btn>
  </div>
</template>

<script>
export default {
    props: {
        eyeEdit: Boolean
    },
    methods: {
        onEdit() {
            this.$emit('onEdit')
        },
        onDelete() {
            this.$emit('onDelete')
        }
    }
}
</script>

<style lang="scss" scoped>
.grid_actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}
</style>